import type { NavLinkData } from '@ltvco/refresh-lib/v1';

export const getObituaryNavLinkData = (llResponseData: any) => {
  let RelativesLinkData: NavLinkData[] = [];
  let LifeEventsLinkData: NavLinkData[] = [];
  let PersonalInterestLinkData: NavLinkData[] = [];
  let FuneralDetailsSectionLinkData: NavLinkData[] = [];

  const {
    relatives,
    accomplishments,
    educationLevel,
    jobs,
    activities,
    hobbies,
    miscellaneous,
    service,
    visitation,
    donations,
  } = llResponseData || {};

  if (relatives?.length > 0) {
    RelativesLinkData = [
      {
        href: '#relatives-section',
        sectionId: '#relatives-section',
        text: 'Relatives',
        reportItemsCount: relatives?.length || 0,
        notificationSection: 'relatives',
      },
    ];
  }

  if (
    accomplishments?.length > 0 ||
    educationLevel?.length > 0 ||
    jobs?.length > 0
  ) {
    LifeEventsLinkData = [
      {
        href: '#life-events-section',
        sectionId: '#life-events-section',
        text: 'Life Events',
        reportItemsCount:
          (accomplishments?.length || 0) +
          (educationLevel?.length || 0) +
          (jobs?.length || 0),
        notificationSection: 'life_events',
      },
    ];
  }

  if (
    activities?.length > 0 ||
    hobbies?.length > 0 ||
    miscellaneous?.length > 0
  ) {
    PersonalInterestLinkData = [
      {
        href: '#personal-interest-section',
        sectionId: '#personal-interest-section',
        text: 'Personal Interests',
        reportItemsCount:
          (activities?.length || 0) +
          (hobbies?.length || 0) +
          (miscellaneous?.length || 0),
        notificationSection: 'personal_interests',
      },
    ];
  }

  const noValidDataList = ['n/a', 'unknown', ''];
  const {
    serviceType = '',
    serviceLocation = '',
    funeralHome = '',
  } = service || {};
  const { visitationDate = '', visitationLocation = '' } = visitation || {};
  const isThereServiceData =
    !noValidDataList.includes(serviceType) ||
    !noValidDataList.includes(serviceLocation) ||
    !noValidDataList.includes(funeralHome);
  const isThereVisitationData =
    !noValidDataList.includes(visitationDate) ||
    !noValidDataList.includes(visitationLocation);

  if (isThereServiceData || isThereVisitationData || donations?.length > 0) {
    FuneralDetailsSectionLinkData = [
      {
        href: '#funeral-details-section',
        sectionId: '#funeral-details-section',
        text: 'Funeral Details',
        reportItemsCount:
          (donations?.length || 0) +
          (isThereServiceData ? 1 : 0) +
          (isThereVisitationData ? 1 : 0),
        notificationSection: 'funeral_details',
      },
    ];
  }

  return [
    {
      href: '#obituary-overview',
      sectionId: '#obituary-overview',
      text: 'Overview',
      reportItemsCount: null,
      hideReportItemsCount: true,
      notificationSection: 'identity',
    },
    {
      href: '#full-obituary-section',
      sectionId: '#full-obituary-section',
      text: 'Full Obituary',
      reportItemsCount: null,
      hideReportItemsCount: true,
      notificationSection: 'identity',
    },
    ...RelativesLinkData,
    ...LifeEventsLinkData,
    ...PersonalInterestLinkData,
    ...FuneralDetailsSectionLinkData,
  ] as NavLinkData[];
};
