import { SvgIcon, type SvgIconProps } from '@ltvco/refresh-lib/theme';

export const FamilyThree = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 32 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>Family of Three</title>
        <g clip-path="url(#clip0_55_7644)">
          <path
            d="M2.42866 14.6336C3.53135 15.731 5.46297 15.5876 5.46297 15.5876C5.46297 15.5876 5.60705 13.6652 4.50436 12.5677C3.40167 11.4703 1.47005 11.6137 1.47005 11.6137C1.47005 11.6137 1.32597 13.5361 2.42866 14.6336Z"
            fill="#638A3A"
          />
          <path
            d="M12.7915 13.1775C13.8942 12.0801 13.7501 10.1576 13.7501 10.1576C13.7501 10.1576 11.8185 10.0142 10.7158 11.1117C9.61308 12.2092 9.75716 14.1316 9.75716 14.1316C9.75716 14.1316 11.6888 14.275 12.7915 13.1775Z"
            fill="#638A3A"
          />
          <path
            d="M3.0404 22.8827C4.14308 21.7852 3.999 19.8628 3.999 19.8628C3.999 19.8628 2.06738 19.7194 0.964694 20.8168C-0.137032 21.9152 0.00608688 23.8367 0.00608688 23.8367C0.00608688 23.8367 1.93771 23.9801 3.0404 22.8827Z"
            fill="#638A3A"
          />
          <path
            d="M2.65783 18.8198C4.20236 19.032 5.65564 17.7577 5.65564 17.7577C5.65564 17.7577 4.60386 16.1392 3.05933 15.927C1.5148 15.7148 0.0615234 16.9891 0.0615234 16.9891C0.0615234 16.9891 1.1133 18.6076 2.65783 18.8198Z"
            fill="#81B44C"
          />
          <path
            d="M8.70981 12.7973C8.92305 14.3345 7.64267 15.7809 7.64267 15.7809C7.64267 15.7809 6.01649 14.7341 5.80325 13.1969C5.59002 11.6597 6.8704 10.2133 6.8704 10.2133C6.8704 10.2133 8.49658 11.2601 8.70981 12.7973Z"
            fill="#81B44C"
          />
          <path
            d="M11.1961 15.2062C9.65152 14.994 8.19824 16.2683 8.19824 16.2683C8.19824 16.2683 9.25002 17.8868 10.7946 18.099C12.3391 18.3112 13.7924 17.0369 13.7924 17.0369C13.7924 17.0369 12.7406 15.4184 11.1961 15.2062Z"
            fill="#638A3A"
          />
          <path
            d="M5.14505 21.2279C4.93181 19.6907 6.2122 18.2443 6.2122 18.2443C6.2122 18.2443 7.83837 19.2911 8.05161 20.8283C8.26485 22.3655 6.98446 23.8119 6.98446 23.8119C6.98446 23.8119 5.35829 22.7651 5.14505 21.2279Z"
            fill="#638A3A"
          />
          <path
            d="M15.1585 26.5603L16.4879 25.2372C11.8418 21.5548 5.76074 15.884 5.76074 15.884C5.76074 15.884 11.4586 21.9372 15.1585 26.5603Z"
            fill="#8E715B"
          />
          <path
            d="M13.5271 23.4066V24.0853C11.3871 23.8444 8.36719 23.7459 8.36719 23.7459C8.36719 23.7459 11.388 23.6484 13.5271 23.4066Z"
            fill="#8E715B"
          />
          <path
            d="M29.5717 14.6336C28.4691 15.731 26.5374 15.5876 26.5374 15.5876C26.5374 15.5876 26.3934 13.6652 27.496 12.5677C28.5987 11.4703 30.5304 11.6137 30.5304 11.6137C30.5304 11.6137 30.6744 13.5361 29.5717 14.6336Z"
            fill="#638A3A"
          />
          <path
            d="M19.2099 13.1775C18.1072 12.0801 18.2513 10.1576 18.2513 10.1576C18.2513 10.1576 20.1829 10.0142 21.2856 11.1117C22.3883 12.2092 22.2442 14.1316 22.2442 14.1316C22.2442 14.1316 20.3126 14.275 19.2099 13.1775Z"
            fill="#638A3A"
          />
          <path
            d="M28.9599 22.8827C27.8572 21.7852 28.0013 19.8628 28.0013 19.8628C28.0013 19.8628 29.9329 19.7194 31.0356 20.8168C32.1383 21.9143 31.9942 23.8367 31.9942 23.8367C31.9942 23.8367 30.0626 23.9801 28.9599 22.8827Z"
            fill="#638A3A"
          />
          <path
            d="M29.3425 18.8198C27.798 19.032 26.3447 17.7577 26.3447 17.7577C26.3447 17.7577 27.3965 16.1392 28.941 15.927C30.4856 15.7148 31.9388 16.9891 31.9388 16.9891C31.9388 16.9891 30.8871 18.6076 29.3425 18.8198Z"
            fill="#81B44C"
          />
          <path
            d="M23.2915 12.7973C23.0783 14.3345 24.3587 15.7809 24.3587 15.7809C24.3587 15.7809 25.9849 14.7341 26.1981 13.1969C26.4113 11.6597 25.1309 10.2133 25.1309 10.2133C25.1309 10.2133 23.5048 11.2601 23.2915 12.7973Z"
            fill="#81B44C"
          />
          <path
            d="M20.8043 15.2062C22.3488 14.994 23.8021 16.2683 23.8021 16.2683C23.8021 16.2683 22.7503 17.8868 21.2058 18.099C19.6613 18.3112 18.208 17.0369 18.208 17.0369C18.208 17.0369 19.2598 15.4184 20.8043 15.2062Z"
            fill="#638A3A"
          />
          <path
            d="M26.8553 21.2279C27.0686 19.6907 25.7882 18.2443 25.7882 18.2443C25.7882 18.2443 24.162 19.2911 23.9488 20.8283C23.7355 22.3655 25.0159 23.8119 25.0159 23.8119C25.0159 23.8119 26.6421 22.7651 26.8553 21.2279Z"
            fill="#638A3A"
          />
          <path
            d="M16.843 26.5603L15.5137 25.2372C20.1597 21.5548 26.2409 15.884 26.2409 15.884C26.2409 15.884 20.543 21.9372 16.843 26.5603Z"
            fill="#8E715B"
          />
          <path
            d="M18.4727 23.4066V24.0853C20.6127 23.8444 23.6326 23.7459 23.6326 23.7459C23.6326 23.7459 20.6118 23.6484 18.4727 23.4066Z"
            fill="#8E715B"
          />
          <path
            d="M19.644 37.0742H12.3564C15.021 37.0742 16.0007 7.13599 16.0007 7.13599C16.0007 7.13599 16.9804 37.0742 19.644 37.0742Z"
            fill="#8E715B"
          />
          <path
            d="M14.5332 3.7355C14.5332 5.28705 15.9999 6.5451 15.9999 6.5451C15.9999 6.5451 17.4667 5.28705 17.4667 3.7355C17.4667 2.18396 15.9999 0.925903 15.9999 0.925903C15.9999 0.925903 14.5332 2.18396 14.5332 3.7355Z"
            fill="#638A3A"
          />
          <path
            d="M11.7218 7.66177C12.6632 8.8988 14.5957 9.02021 14.5957 9.02021C14.5957 9.02021 15.002 7.13599 14.0607 5.89896C13.1194 4.66193 11.1868 4.54053 11.1868 4.54053C11.1868 4.54053 10.7805 6.42475 11.7218 7.66177Z"
            fill="#81B44C"
          />
          <path
            d="M20.2795 7.66177C19.3382 8.8988 17.4056 9.02021 17.4056 9.02021C17.4056 9.02021 16.9993 7.13599 17.9406 5.89896C18.8819 4.66193 20.8145 4.54053 20.8145 4.54053C20.8145 4.54053 21.2208 6.42475 20.2795 7.66177Z"
            fill="#81B44C"
          />
        </g>
        <defs>
          <clipPath id="clip0_55_7644">
            <rect width="32" height="38" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};
