import { SvgIcon, SvgIconProps } from '@ltvco/refresh-lib/theme';

export const SearchReport = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="117"
        height="100"
        viewBox="0 0 117 100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M55.6467 0.0976474C73.7228 -1.29706 74.8586 12.5791 88.5184 20.608C94.474 24.1097 115.435 23.6062 115.412 42.114C115.393 56.9337 98.0571 61.2968 96.0022 67.2112C93.9472 73.1294 94.5899 89.5115 78.4081 92.4874C57.059 96.4142 52.015 78.5665 39.1211 77.526C26.2273 76.4856 11.8725 75.3109 9.57843 59.0854C7.15358 41.9313 27.4117 34.7788 30.4418 25.9146C33.4719 17.0504 35.2877 1.66762 55.6467 0.0976474Z"
          fill="#e5eeff"
        />
        <path
          d="M88.3228 70.8852L72.9161 55.4786C72.2207 54.7832 71.0907 54.7832 70.3988 55.4786L70.2284 55.6489L66.5845 52.005C68.9106 49.0809 70.3049 45.3813 70.3049 41.3619C70.3049 31.9219 62.6242 24.2446 53.1876 24.2446C43.751 24.2446 36.0703 31.9253 36.0703 41.3619C36.0703 50.7985 43.751 58.4792 53.1876 58.4792C57.0923 58.4792 60.691 57.1649 63.5769 54.957L67.2486 58.6287L67.0783 58.7991C66.3829 59.4945 66.3829 60.6245 67.0783 61.3165L82.4849 76.7231C82.8326 77.0708 83.288 77.2446 83.7435 77.2446C84.199 77.2446 84.6545 77.0708 85.0022 76.7231L88.3262 73.3991C89.0216 72.7037 89.0216 71.5736 88.3262 70.8817L88.3228 70.8852ZM87.0815 69.7412L81.3583 75.4644L87.0815 69.7412Z"
          fill="white"
        />
        <path
          d="M87.0815 69.7412L81.3583 75.4644M88.3228 70.8852L72.9161 55.4786C72.2207 54.7832 71.0907 54.7832 70.3988 55.4786L70.2284 55.6489L66.5845 52.005C68.9106 49.0809 70.3049 45.3813 70.3049 41.3619C70.3049 31.9219 62.6242 24.2446 53.1876 24.2446C43.751 24.2446 36.0703 31.9253 36.0703 41.3619C36.0703 50.7985 43.751 58.4792 53.1876 58.4792C57.0923 58.4792 60.691 57.1649 63.5769 54.957L67.2486 58.6287L67.0783 58.7991C66.3829 59.4945 66.3829 60.6245 67.0783 61.3165L82.4849 76.7231C82.8326 77.0708 83.288 77.2446 83.7435 77.2446C84.199 77.2446 84.6545 77.0708 85.0022 76.7231L88.3262 73.3991C89.0216 72.7037 89.0216 71.5736 88.3262 70.8817L88.3228 70.8852Z"
          stroke="#0052FF"
          stroke-width="1.2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M53.1878 27.1965C45.375 27.1965 39.0225 33.5525 39.0225 41.3619C39.0225 49.1712 45.3784 55.5272 53.1878 55.5272C60.9971 55.5272 67.3531 49.1712 67.3531 41.3619C67.3531 33.5525 60.9971 27.1965 53.1878 27.1965Z"
          fill="white"
          stroke="#0052FF"
          stroke-width="1.2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M53.4902 29.9922C53.598 29.9991 56.178 30.2078 58.89 31.8628C61.4004 33.3962 64.495 36.5011 64.9852 42.5929"
          fill="white"
        />
        <path
          d="M53.4902 29.9922C53.598 29.9991 56.178 30.2078 58.89 31.8628C61.4004 33.3962 64.495 36.5011 64.9852 42.5929"
          stroke="#0052FF"
          stroke-width="1.2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M38.9839 71.4111H24.2078C23.192 71.4111 22.3701 72.2423 22.3701 73.2581L22.3608 84.3402C22.3608 85.3561 23.192 86.1872 24.2078 86.1872H38.9839C39.9998 86.1872 40.8309 85.3561 40.8309 84.3402V73.2581C40.8309 72.2423 39.9998 71.4111 38.9839 71.4111ZM38.6145 75.336L32.0853 79.4179C31.7898 79.6026 31.402 79.6026 31.1064 79.4179L24.5773 75.336C24.3464 75.1883 24.2078 74.9389 24.2078 74.6711C24.2078 74.0524 24.882 73.683 25.4084 74.0062L31.5959 77.8757L37.7834 74.0062C38.3098 73.683 38.9839 74.0524 38.9839 74.6711C38.9839 74.9389 38.8454 75.1883 38.6145 75.336Z"
          fill="#0052FF"
        />
        <path
          d="M113.459 36.7876H105.437C103.772 36.7876 102.428 38.1314 102.428 39.796V55.8409C102.428 57.5055 103.772 58.8493 105.437 58.8493H113.459C115.124 58.8493 116.467 57.5055 116.467 55.8409V39.796C116.467 38.1314 115.124 36.7876 113.459 36.7876ZM110.952 56.8437H107.944C107.663 56.8437 107.442 56.6231 107.442 56.3423C107.442 56.0615 107.663 55.8409 107.944 55.8409H110.952C111.233 55.8409 111.453 56.0615 111.453 56.3423C111.453 56.6231 111.233 56.8437 110.952 56.8437ZM114.462 53.8353H104.434V39.796H114.462V53.8353Z"
          fill="#0052FF"
        />
        <path
          d="M33.8282 0.0257456C27.7212 -0.40546 22.6771 4.63865 23.1083 10.7457C23.4693 16.0506 28.1022 20.0518 33.4171 20.0518H37.1175C37.669 20.0518 38.1203 19.6005 38.1203 19.049C38.1203 18.4974 37.669 18.0462 37.1175 18.0462H33.4372C29.6967 18.0462 26.2671 15.6194 25.3345 11.9992C23.8403 6.18297 29.2555 0.757793 35.0717 2.262C38.7019 3.18458 41.1287 6.61417 41.1287 10.3546V11.4577C41.1287 12.2499 40.4167 13.0321 39.6245 13.0321C38.8322 13.0321 38.1203 12.2499 38.1203 11.4577V10.2042C38.1203 7.68717 36.3353 5.42084 33.8483 5.06985C30.4388 4.57848 27.5607 7.52673 28.1724 10.9563C28.5134 12.8717 30.0076 14.4561 31.9029 14.9074C33.748 15.3386 35.5029 14.7469 36.6562 13.5736C37.5487 14.7971 39.3337 15.4389 40.9682 14.787C42.312 14.2555 43.1343 12.8817 43.1343 11.4377V10.3446C43.1343 5.01971 39.1331 0.386755 33.8282 0.0257456ZM33.1062 13.0321C31.4416 13.0321 30.0978 11.6884 30.0978 10.0237C30.0978 8.35905 31.4416 7.0153 33.1062 7.0153C34.7709 7.0153 36.1146 8.35905 36.1146 10.0237C36.1146 11.6884 34.7709 13.0321 33.1062 13.0321Z"
          fill="#0052FF"
        />
        <path
          d="M15.9647 32.0297C15.7641 31.438 15.2025 31.0168 14.5407 31.0168H3.50982C2.84797 31.0168 2.29642 31.438 2.08583 32.0297L0.110308 37.7156C0.0401121 37.9262 0 38.1468 0 38.3774V45.5575C0 46.3898 0.671879 47.0617 1.50421 47.0617C2.33653 47.0617 3.00841 46.3898 3.00841 45.5575V45.0561H15.0421V45.5575C15.0421 46.3798 15.714 47.0617 16.5463 47.0617C17.3686 47.0617 18.0505 46.3898 18.0505 45.5575V38.3774C18.0505 38.1568 18.0104 37.9262 17.9402 37.7156L15.9647 32.0297ZM3.50982 42.0477C2.67749 42.0477 2.00561 41.3758 2.00561 40.5435C2.00561 39.7112 2.67749 39.0393 3.50982 39.0393C4.34214 39.0393 5.01402 39.7112 5.01402 40.5435C5.01402 41.3758 4.34214 42.0477 3.50982 42.0477ZM14.5407 42.0477C13.7083 42.0477 13.0365 41.3758 13.0365 40.5435C13.0365 39.7112 13.7083 39.0393 14.5407 39.0393C15.373 39.0393 16.0449 39.7112 16.0449 40.5435C16.0449 41.3758 15.373 42.0477 14.5407 42.0477ZM2.00561 37.0337L3.27917 33.203C3.41956 32.8018 3.80063 32.5211 4.23184 32.5211H13.8187C14.2499 32.5211 14.6309 32.8018 14.7713 33.203L16.0449 37.0337H2.00561Z"
          fill="#0052FF"
        />
        <path
          d="M77.9907 100V93.9832H82.0019V100H87.0159V91.9776H90.0244L79.9963 82.9524L69.9683 91.9776H72.9767V100H77.9907Z"
          fill="#0052FF"
        />
        <path
          d="M82.3188 10.1865C84.535 10.1865 86.33 8.39148 86.33 6.17528C86.33 3.95908 84.535 2.16406 82.3188 2.16406C80.1026 2.16406 78.3076 3.95908 78.3076 6.17528C78.3076 8.39148 80.1026 10.1865 82.3188 10.1865ZM82.3188 12.1921C79.6413 12.1921 74.2964 13.5359 74.2964 16.2033V17.2061C74.2964 17.7577 74.7476 18.2089 75.2992 18.2089H89.3385C89.89 18.2089 90.3413 17.7577 90.3413 17.2061V16.2033C90.3413 13.5359 84.9963 12.1921 82.3188 12.1921Z"
          fill="#0052FF"
        />
      </svg>
    </SvgIcon>
  );
};
