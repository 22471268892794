import { SvgIcon, type SvgIconProps } from '@ltvco/refresh-lib/theme';

export const Monitoring = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>Monitoring</title>
        <g clip-path="url(#clip0_260_641)">
          <mask
            id="mask0_260_641"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="25"
            height="24"
          >
            <path d="M24.1677 0H0.603638V24H24.1677V0Z" fill="white" />
          </mask>
          <g mask="url(#mask0_260_641)">
            <path
              d="M17.0984 19.6355C17.0984 20.4986 16.822 21.3422 16.3042 22.0598C15.7863 22.7774 15.0503 23.3366 14.1892 23.6669C13.3281 23.9972 12.3805 24.0836 11.4663 23.9152C10.5522 23.7469 9.71246 23.3313 9.05342 22.721C8.39434 22.1108 7.94549 21.3333 7.76366 20.4868C7.58179 19.6404 7.67515 18.763 8.03184 17.9657C8.38853 17.1683 8.99256 16.4868 9.76752 16.0074C10.5425 15.5279 11.4537 15.272 12.3857 15.272C13.6356 15.272 14.8343 15.7317 15.7181 16.55C16.6019 17.3683 17.0984 18.4782 17.0984 19.6355Z"
              fill="#FFA000"
            />
            <path
              d="M20.6419 10.8226C19.3833 10.6582 18.1828 10.226 17.1349 9.55988C16.0869 8.89375 15.2199 8.01188 14.6018 6.9834C13.9837 5.95495 13.6313 4.80784 13.5724 3.63214C13.5136 2.45642 13.7496 1.28406 14.2622 0.207009C13.0527 -0.0598444 11.7936 -0.069123 10.5796 0.179868C9.36568 0.428859 8.22856 0.929631 7.25392 1.64448C6.27928 2.35932 5.49251 3.2696 4.95282 4.30677C4.41315 5.34393 4.13467 6.481 4.13834 7.63224V10.6774C4.1391 11.7447 3.88761 12.7992 3.40151 13.767C2.91541 14.7347 2.20648 15.5922 1.32447 16.2794C1.00492 16.5329 0.776832 16.871 0.670837 17.2481C0.564841 17.6252 0.586017 18.0233 0.731521 18.389C0.877027 18.7546 1.13989 19.0703 1.4848 19.2936C1.82972 19.5168 2.24016 19.637 2.66101 19.638H22.105C22.5278 19.6381 22.9403 19.5178 23.2867 19.2935C23.6332 19.0693 23.8968 18.7518 24.0419 18.3841C24.187 18.0165 24.2065 17.6165 24.0978 17.2382C23.9892 16.86 23.7576 16.5218 23.4344 16.2694C22.5771 15.5979 21.8845 14.7646 21.4029 13.8253C20.9213 12.886 20.6619 11.8622 20.6419 10.8226Z"
              fill="#FFC107"
            />
            <path
              d="M4.12855 10.8226C6.09108 10.5614 7.88647 9.65469 9.18622 8.26833C10.486 6.88198 11.2033 5.10869 11.2069 3.27283C11.2068 2.21703 10.9689 1.17286 10.5083 0.207034C11.7178 -0.0596538 12.9769 -0.0688125 14.1907 0.180244C15.4046 0.429301 16.5416 0.930086 17.5162 1.6449C18.4909 2.35971 19.2776 3.26992 19.8174 4.30702C20.3571 5.34411 20.6357 6.48104 20.6322 7.63229V10.6774C20.6314 11.7447 20.8829 12.7992 21.369 13.767C21.8551 14.7347 22.564 15.5922 23.4461 16.2794C23.7656 16.533 23.9937 16.871 24.0997 17.2481C24.2057 17.6252 24.1845 18.0234 24.039 18.389C23.8935 18.7546 23.6306 19.0703 23.2857 19.2936C22.9408 19.5169 22.5304 19.637 22.1095 19.6381H2.66549C2.24214 19.6387 1.82883 19.5187 1.48172 19.2943C1.1346 19.0699 0.870509 18.752 0.725299 18.3838C0.580091 18.0155 0.560814 17.6149 0.670086 17.2362C0.779358 16.8574 1.01188 16.5191 1.33607 16.267C2.1931 15.5957 2.88553 14.7628 3.36709 13.824C3.84865 12.8851 4.10826 11.8618 4.12855 10.8226Z"
              fill="#FFC107"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_260_641">
            <rect
              width="24"
              height="24"
              fill="white"
              transform="translate(0.333374)"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};
