import { NavLinkData, Owner } from '@ltvco/refresh-lib/v1';
import { Phone, Work, TaskAlt, AccountCircle } from '@mui/icons-material';
import { useTheme } from '@ltvco/refresh-lib/theme';
import { formatCompany } from '@ltvco/refresh-lib/utils';

export const getContactNavLinkData = (
  owner: Owner,
  contactsByCompanyLength: number,
  relatedByCompanyFetched?: boolean
): NavLinkData[] => {
  const filteredAddresses = owner.addresses
    ? owner.addresses.filter((address) => address.parsed !== null)
    : [];

  const maxDisplayedKeyContactsCount = 10;
  const keyContactsCount = Math.min(
    maxDisplayedKeyContactsCount,
    contactsByCompanyLength
  );
  return [
    {
      href: '#contact-overview',
      sectionId: '#contact-overview',
      text: 'Overview',
      reportItemsCount: null,
      hideReportItemsCount: true,
      notificationSection: 'identity',
    },
    {
      href: '#email-section',
      sectionId: '#email-section',
      text: 'Email Addresses',
      reportItemsCount: owner.emails.length,
      notificationSection: 'emails',
    },
    {
      href: '#phone-numbers-section',
      sectionId: '#phone-numbers-section',
      text: 'Phone Numbers',
      reportItemsCount: owner.phones.length,
      notificationSection: 'phones',
    },
    {
      href: '#social-media-section',
      sectionId: '#social-media-section',
      text: 'Social Media',
      reportItemsCount: owner.profiles.length,
      notificationSection: 'socials',
    },
    {
      href: '#jobs-section',
      sectionId: '#jobs-section',
      text: 'Employment History',
      reportItemsCount: owner.jobs.length,
      notificationSection: 'jobs',
    },
    {
      href: '#education-section',
      sectionId: '#education-section',
      text: 'Education',
      reportItemsCount: owner.educations.length,
      notificationSection: 'educations',
    },
    {
      href: '#address-history-section',
      sectionId: '#address-history-section',
      text: 'Possible Address History',
      reportItemsCount: filteredAddresses.length,
      notificationSection: 'addresses',
    },
    {
      href: '#key-contacts-section',
      sectionId: '#key-contacts-section',
      text: 'Key Contacts',
      reportItemsCount: keyContactsCount,
      hideReportItemsCount: !relatedByCompanyFetched,
    },
  ];
};

export const getContactNavLinkDataV2 = (reportOwner: Owner) => {
  const job = reportOwner?.jobs?.[0];
  const company = job ? job?.company : '';

  const theme = useTheme();
  return [
    {
      href: '#contact-overview',
      labelText: 'Overview',
      icons: [
        <AccountCircle
          sx={{ color: theme.palette.primary.main, fontSize: '2.5rem' }}
        />,
      ],
      name: 'contact-overview',
    },
    {
      labelText: 'Contact Information',
      icons: [
        <Phone
          sx={{ color: theme.palette.primary.main, fontSize: '1.4rem' }}
        />,
      ],
      name: 'contact-information',
      childItems: [
        {
          href: '#phone-numbers-section',
          labelText: 'Phone Numbers',
          reportItemsCount: reportOwner.phones.length,
          name: 'phone-numbers',
        },
        {
          href: '#email-section',
          labelText: 'Email Addresses',
          reportItemsCount: reportOwner.emails.length,
          name: 'email-addresses',
        },
        {
          href: '#address-history-section',
          labelText: 'Address History',
          reportItemsCount: reportOwner.addresses.length,
          name: 'address-history',
        },
        {
          href: '#social-media-section',
          labelText: 'Social Media',
          reportItemsCount:
            reportOwner.profiles.length + reportOwner.usernames.length,
          name: 'social-media',
        },
      ],
    },
    {
      labelText: 'Jobs & Education',
      name: 'jobs-education',
      icons: [
        <Work sx={{ color: theme.palette.primary.main, fontSize: '1.4rem' }} />,
      ],
      childItems: [
        {
          href: '#jobs-section',
          labelText: 'Jobs',
          reportItemsCount: reportOwner.jobs.length,
          name: 'jobs',
        },
        {
          href: '#education-section',
          labelText: 'Education',
          reportItemsCount: reportOwner.educations.length,
          name: 'education',
        },
      ],
    },
    {
      labelText: 'Key Contacts',
      name: 'key-contacts-section',
      icons: [
        <AccountCircle
          sx={{ color: theme.palette.primary.main, fontSize: '1.4rem' }}
        />,
      ],
      childItems: [
        {
          href: '#key-contacts-section',
          labelText: `At ${formatCompany(company ?? '')}`,
          reportItemsCount: reportOwner.jobs.length,
          name: 'possible-key-contacts-at',
        },

        {
          href: '#key-contacts-similar-section',
          labelText: 'With Similar Job Titles',
          reportItemsCount: reportOwner.jobs.length,
          name: 'possible-key-contacts-with-simmilar-job-titles',
        },
      ],
    },
    {
      labelText: 'Next Steps',
      icons: [<TaskAlt sx={{ color: '#0052FF', fontSize: '1.4rem' }} />],
      name: 'next-steps',
      childItems: [
        {
          href: '#Notes-section',
          labelText: 'Notes',
          hideReportItemsCount: true,
          name: 'notes-section',
        },
        {
          href: '#report-rating',
          labelText: 'Report Rating',
          hideReportItemsCount: true,
          name: 'report-rating',
        },
      ],
    },
  ];
};
