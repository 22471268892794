import { request } from '@ltvco/refresh-lib/utils';
import { GetAchievementsResponse } from './interfaces';

interface CloudCannonInterface {
  getAchievementsRequest: () => Promise<GetAchievementsResponse>;
}

const getAchievementsRequest = async () => {
  return await request('/achievements/index.json', {});
};

export const CloudCannonRequests: CloudCannonInterface = {
  getAchievementsRequest,
};
