import { SvgIcon, type SvgIconProps } from '@ltvco/refresh-lib/theme';

export const IdentityBg = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_9324_6531"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="25"
          height="25"
        >
          <path
            d="M24.7191 0.290161H0.947266V24.2902H24.7191V0.290161Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_9324_6531)">
          <path
            d="M21.9953 3.71838H3.67109C2.94887 3.71909 2.25641 4.05044 1.74572 4.63969C1.23504 5.22894 0.947876 6.02795 0.947266 6.86126V21.1473C0.947876 21.9807 1.23504 22.7797 1.74572 23.3689C2.25641 23.9582 2.94887 24.2895 3.67109 24.2902H21.9953C22.7175 24.2895 23.41 23.9582 23.9207 23.3689C24.4314 22.7797 24.7185 21.9807 24.7191 21.1473V6.86126C24.7185 6.02795 24.4314 5.22894 23.9207 4.63969C23.41 4.05044 22.7175 3.71909 21.9953 3.71838Z"
            fill="#D5E7FF"
          />
          <path
            d="M15.0622 0.290161H10.6082C10.1487 0.290864 9.70824 0.501788 9.38332 0.876679C9.05844 1.25157 8.87564 1.75983 8.875 2.29001V4.57607C8.87564 5.10622 9.05844 5.61451 9.38332 5.98938C9.70824 6.36428 10.1487 6.57521 10.6082 6.5759H15.0622C15.5216 6.57521 15.9621 6.36428 16.287 5.98938C16.6119 5.61451 16.7948 5.10622 16.7954 4.57607V2.29001C16.7948 1.75983 16.6119 1.25157 16.287 0.876679C15.9621 0.501788 15.5216 0.290864 15.0622 0.290161Z"
            fill="#ABB1B4"
          />
          <path
            d="M21.0051 10.5765H15.5574C15.3604 10.5765 15.1714 10.4862 15.0321 10.3254C14.8927 10.1646 14.8145 9.94655 14.8145 9.7192C14.8145 9.49185 14.8927 9.27377 15.0321 9.11301C15.1714 8.95226 15.3604 8.86194 15.5574 8.86194H21.0051C21.2021 8.86194 21.3911 8.95226 21.5304 9.11301C21.6697 9.27377 21.748 9.49185 21.748 9.7192C21.748 9.94655 21.6697 10.1646 21.5304 10.3254C21.3911 10.4862 21.2021 10.5765 21.0051 10.5765Z"
            fill="#607D8B"
          />
          <path
            d="M21.0051 15.1477H15.5574C15.3604 15.1477 15.1714 15.0574 15.0321 14.8966C14.8927 14.7358 14.8145 14.5178 14.8145 14.2904C14.8145 14.0631 14.8927 13.845 15.0321 13.6842C15.1714 13.5234 15.3604 13.4332 15.5574 13.4332H21.0051C21.2021 13.4332 21.3911 13.5234 21.5304 13.6842C21.6697 13.845 21.748 14.0631 21.748 14.2904C21.748 14.5178 21.6697 14.7358 21.5304 14.8966C21.3911 15.0574 21.2021 15.1477 21.0051 15.1477Z"
            fill="#607D8B"
          />
          <path
            d="M21.0051 19.7171H15.5574C15.3604 19.7171 15.1714 19.6268 15.0321 19.466C14.8927 19.3052 14.8145 19.0872 14.8145 18.8599C14.8145 18.6325 14.8927 18.4144 15.0321 18.2536C15.1714 18.0929 15.3604 18.0026 15.5574 18.0026H21.0051C21.2021 18.0026 21.3911 18.0929 21.5304 18.2536C21.6697 18.4144 21.748 18.6325 21.748 18.8599C21.748 19.0872 21.6697 19.3052 21.5304 19.466C21.3911 19.6268 21.2021 19.7171 21.0051 19.7171Z"
            fill="#607D8B"
          />
          <path
            d="M8.7686 13.5812H8.7732L10.9234 12.7368C10.9303 12.6935 10.9332 12.6496 10.9319 12.6057C10.9327 12.5625 10.9268 12.5196 10.9142 12.4789C10.8759 12.3503 10.8095 12.2353 10.7215 12.1449V11.0373C10.7532 10.7819 10.7213 10.5215 10.6297 10.2861C10.5381 10.0508 10.3904 9.85043 10.2039 9.70818C10.0421 9.32335 9.7808 9.00586 9.45608 8.79955C9.13136 8.59325 8.75916 8.50828 8.3908 8.55628C7.81568 8.59274 7.26992 8.86112 6.84888 9.31458C6.4278 9.76805 6.15816 10.3778 6.087 11.0373C6.087 11.4422 6.07396 11.7604 6.06092 12.0031C6.06092 12.0536 6.05632 12.0997 6.05632 12.1396C5.963 12.2392 5.89616 12.3673 5.86356 12.5091C5.85824 12.5409 5.8554 12.5732 5.85512 12.6057C5.88 12.961 5.95388 13.3095 6.07396 13.6379C6.08768 13.6813 6.10876 13.7211 6.13592 13.755C6.16304 13.7889 6.19576 13.8163 6.23216 13.8355C6.23904 13.8722 6.24188 13.9097 6.2406 13.9471C6.301 14.4492 6.5214 14.9073 6.8588 15.232C6.83728 15.7884 6.7006 16.1729 6.50784 16.2695L4.78923 16.9279C4.55029 17.0197 4.33534 17.1797 4.16431 17.393C3.99327 17.6063 3.87171 17.866 3.81089 18.148L3.58051 19.2201C3.56965 19.2761 3.56952 19.3341 3.58013 19.3901C3.59075 19.4461 3.61184 19.4988 3.64195 19.5444C3.6727 19.5898 3.71197 19.6265 3.75679 19.6518C3.80161 19.6771 3.85083 19.6904 3.90074 19.6907H8.81544C8.67944 19.4903 8.55628 19.2789 8.44684 19.058C8.1262 18.4102 7.95684 17.6774 7.95536 16.9314V14.9015C7.9562 14.6109 8.03516 14.3279 8.1814 14.0914C8.32764 13.8549 8.53388 13.6766 8.77168 13.5812H8.7686Z"
            fill="#42A5F5"
          />
          <path
            d="M8.14144 13.5812H8.13684L5.98664 12.7368C5.97996 12.6932 5.9774 12.649 5.97896 12.6048C5.97812 12.5616 5.98412 12.5187 5.99664 12.4781C6.03496 12.3495 6.10136 12.2345 6.18936 12.144V11.0373C6.15772 10.782 6.18952 10.5215 6.28116 10.2862C6.3728 10.0509 6.52044 9.85043 6.70696 9.70819C6.86872 9.32349 7.12984 9.00614 7.45444 8.79988C7.779 8.59357 8.15104 8.50846 8.51924 8.55632C9.09436 8.59274 9.64012 8.86112 10.0612 9.31459C10.4822 9.76805 10.7519 10.3778 10.823 11.0373C10.823 11.4423 10.8361 11.7604 10.8492 12.0031C10.8492 12.0536 10.8538 12.0997 10.8538 12.1396C10.947 12.2392 11.0139 12.3673 11.0465 12.5091C11.0518 12.5409 11.0547 12.5732 11.055 12.6057C11.0301 12.961 10.9562 13.3095 10.8361 13.6379C10.8224 13.6813 10.8013 13.7211 10.7742 13.755C10.747 13.7889 10.7143 13.8163 10.6779 13.8355C10.671 13.8722 10.6682 13.9097 10.6694 13.9472C10.609 14.4492 10.3886 14.9073 10.0513 15.232C10.0728 15.7884 10.2095 16.1729 10.4022 16.2695L12.1224 16.9261C12.3613 17.018 12.5762 17.1779 12.7473 17.3913C12.9183 17.6045 13.0399 17.8643 13.1007 18.1463L13.3311 19.2184C13.3419 19.2743 13.3421 19.3324 13.3314 19.3884C13.3208 19.4444 13.2998 19.497 13.2696 19.5427C13.2389 19.5881 13.1996 19.6247 13.1548 19.6501C13.11 19.6754 13.0608 19.6887 13.0108 19.6889H8.09612C8.22744 19.4966 7.70448 19.2512 7.81816 19.0235C8.10764 18.4315 7.26216 18.6823 7.26216 17.8715L7.50712 15.2346C7.50712 14.6374 8.626 13.7815 8.13912 13.5848L8.14144 13.5812Z"
            fill="#42A5F5"
          />
        </g>
      </svg>
    </SvgIcon>
  );
};
