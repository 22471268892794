import { SvgIcon, type SvgIconProps } from '@ltvco/refresh-lib/theme';

export const TheftInsurance = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>Theft insurance</title>
        <mask
          id="mask0_260_234"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="25"
          height="25"
        >
          <path
            d="M24.2742 0.583008H0.72702V24.583H24.2742V0.583008Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_260_234)">
          <path
            d="M12.3621 10.5131H12.3721L17.1782 8.84353C17.1936 8.75805 17.2 8.6711 17.1971 8.58424C17.199 8.49908 17.1856 8.4143 17.1575 8.33408C17.0717 8.08005 16.923 7.85284 16.726 7.67431V5.48436C16.726 3.86432 16.1569 3.22382 15.5681 2.85454C15.266 1.91349 14.0002 0.583008 11.5144 0.583008C10.2265 0.652357 9.00368 1.18134 8.06003 2.07728C7.11642 2.97324 6.51212 4.17902 6.35308 5.48344C6.35308 6.28245 6.32342 6.91287 6.29467 7.39301C6.29467 7.49381 6.28475 7.58362 6.28475 7.66333C6.0755 7.86092 5.92579 8.11518 5.85325 8.39639C5.84134 8.45921 5.835 8.52299 5.83437 8.58696C5.89016 9.2891 6.05557 9.97767 6.32433 10.6267C6.35499 10.7123 6.40203 10.7909 6.46267 10.8579C6.5233 10.9249 6.59639 10.979 6.67763 11.0171C6.69311 11.0895 6.69945 11.1637 6.69651 11.2379C6.83272 12.2308 7.32694 13.1363 8.0828 13.7779C8.03426 14.8775 7.72951 15.638 7.29886 15.8286L3.45376 17.1288C2.9199 17.31 2.43951 17.6255 2.05702 18.0461C1.67453 18.4667 1.4023 18.9788 1.26554 19.5351L0.745003 21.6581C0.720484 21.7687 0.720012 21.8835 0.743619 21.9943C0.767226 22.1051 0.81434 22.2093 0.881655 22.2995C0.950275 22.3892 1.03794 22.4619 1.13806 22.512C1.23819 22.5622 1.34816 22.5885 1.45972 22.5891H12.4593C12.1551 22.1934 11.8796 21.7757 11.6348 21.3393C10.918 20.06 10.5391 18.613 10.5353 17.1398V13.1227C10.5369 12.5485 10.7134 11.989 11.0405 11.5215C11.3676 11.054 11.8291 10.7016 12.3613 10.5131H12.3621Z"
            fill="#806ED6"
          />
          <path
            d="M23.7734 12.4081L18.623 10.6231C18.4694 10.5691 18.3027 10.5691 18.1492 10.6231L12.9987 12.4081C12.8532 12.4583 12.7268 12.5537 12.6373 12.681C12.5478 12.8082 12.4997 12.9608 12.4997 13.1173V17.1354C12.4997 22.0404 17.8696 24.4218 18.0997 24.5226C18.1907 24.562 18.2886 24.5822 18.3874 24.5822C18.4862 24.5822 18.5841 24.562 18.6751 24.5226C18.9043 24.4237 24.2751 22.0404 24.2751 17.1354V13.1173C24.2747 12.9606 24.2262 12.808 24.1364 12.6808C24.0466 12.5536 23.9191 12.4582 23.7734 12.4081ZM21.6095 16.2025L18.9125 19.7019C18.8307 19.808 18.7287 19.896 18.6126 19.9609C18.4966 20.0257 18.3689 20.0659 18.2371 20.0791C18.1055 20.0923 17.9725 20.0782 17.8463 20.0376C17.7201 19.997 17.6032 19.9308 17.5028 19.8431L15.7857 18.343C15.594 18.1689 15.4771 17.9251 15.4598 17.664C15.4426 17.403 15.5265 17.1454 15.6935 16.9467C15.8604 16.7479 16.0972 16.6238 16.353 16.601C16.6087 16.5782 16.863 16.6585 17.0614 16.8247L18.0017 17.6494L20.0695 14.9701C20.1456 14.8578 20.2434 14.7627 20.3569 14.6904C20.4704 14.6182 20.5972 14.5704 20.7296 14.5499C20.8619 14.5295 20.997 14.5369 21.1264 14.5716C21.2558 14.6064 21.3769 14.6678 21.4822 14.7521C21.5875 14.8363 21.6747 14.9416 21.7385 15.0615C21.8024 15.1814 21.8414 15.3134 21.8534 15.4493C21.8653 15.5851 21.8498 15.722 21.8079 15.8516C21.766 15.9811 21.6984 16.1006 21.6095 16.2025Z"
            fill="#433584"
          />
        </g>
      </svg>
    </SvgIcon>
  );
};
