
import { request } from '@ltvco/refresh-lib/utils';
import {
  Chat,
  CreateChatParams,
  CreateMessageParams,
  CreateChatResponse,
  GetLanguagesResponse,
  GetTopicsResponse,
  GetChatsResponse,
  LessonChatResponse,
  GetMessagesResponse,
  RequestResponse,
  UserResponse,
  UserParams,
  HintsResponse,
  WordTranslationResponse,
  Translation,
  GetLessonChatsResponse,
  CreateLessonChatParams,
  GetQuizChatsResponse,
  GetQuizChatResponse,
  CreateQuizChatParams,
  GetStoryChatsResponse,
  GetStoryChatResponse,
  CreateStoryChatParams,
  GetUserAchievementsResponse,
  CreateUserRequirementParams,
  CreateUserRequirementResponse,
  GetUserStreakResponse,
} from './interfaces';

const bumpeeURL = '/ms/bumpee';
const bumpeeBeverlyURL = `${bumpeeURL}/beverly`;

interface BumpeeRequestsInterface {
  createUser: (token: string, params: UserParams) => Promise<RequestResponse>;
  getUser: (token: string) => Promise<UserResponse>;
  updateUser: (token: string, params: UserParams) => Promise<RequestResponse>;
  getLanguages: (token: string) => Promise<GetLanguagesResponse>;
  getTopics: (token: string) => Promise<GetTopicsResponse>;
  createChat: (
    token: string,
    params: CreateChatParams
  ) => Promise<CreateChatResponse>;
  getChats: (token: string) => Promise<GetChatsResponse>;
  getChat: (token: string, chatId: number) => Promise<Chat>;
  deleteChat: (token: string, chatId: number) => Promise<RequestResponse>;
  getMessages: (
    token: string,
    chatId: number,
    pageParam: number
  ) => Promise<GetMessagesResponse>;
  createMessage: (
    token: string,
    params: CreateMessageParams
  ) => Promise<RequestResponse>;
  getMessageTranslation: (
    token: string,
    chatId: string | number,
    messageId: string | number
  ) => Promise<RequestResponse>;
  getTextTranslation: (
    token: string,
    text: string,
    chatId: string | number
  ) => Promise<Translation>;
  getHint: (
    token: string,
    chatId?: string | number,
    messageId?: string | number
  ) => Promise<HintsResponse>;
  getWordTranslation: (
    token: string,
    chatId: number,
    messageId: number,
    index: number
  ) => Promise<WordTranslationResponse>;
  getMessageAudio: (
    token: string,
    chatId?: string | number,
    messageId?: string | number
  ) => Promise<RequestResponse>;
  getLessonChat: (
    token: string,
    lessonChatId: string
  ) => Promise<LessonChatResponse>;
  createLessonChatMessage: (
    token: string,
    params: CreateMessageParams,
    lessonChatId: string | number
  ) => Promise<RequestResponse>;
  getLessonChats: (token: string) => Promise<GetLessonChatsResponse>;
  createLessonChat: (
    token: string,
    params: CreateLessonChatParams
  ) => Promise<any>;
  completeLesson: (
    token: string,
    params: CreateMessageParams,
    lessonChatId: string
  ) => Promise<RequestResponse>;
  getHintAudio: (
    token: string,
    chatId: string | number,
    text: string
  ) => Promise<RequestResponse>;
  getQuizChats: (token: string) => Promise<GetQuizChatsResponse>;
  getQuizChat: (token: string, quizId: string) => Promise<GetQuizChatResponse>;
  createQuizChat: (
    token: string,
    params: CreateLessonChatParams
  ) => Promise<any>;
  createQuizChatMessage: (
    token: string,
    params: CreateMessageParams,
    quizChatId: number
  ) => Promise<RequestResponse>;
  retakeQuiz: (token: string, quizChatId: number) => Promise<RequestResponse>;
  getStoryChats: (token: string) => Promise<GetStoryChatsResponse>;
  getStoryChat: (
    token: string,
    quizId: string
  ) => Promise<GetStoryChatResponse>;
  createStoryChat: (
    token: string,
    params: CreateLessonChatParams
  ) => Promise<any>;
  createStoryChatMessage: (
    token: string,
    params: CreateMessageParams,
    storyChatId: number
  ) => Promise<RequestResponse>;
  getStoryAudio: (
    token: string,
    chatId: string | number,
    segmentIndex: string | number
  ) => Promise<RequestResponse>;
  getUserAchievements: (token: string) => Promise<GetUserAchievementsResponse>;
  createUserRequirement: (
    token: string,
    params: CreateUserRequirementParams
  ) => Promise<CreateUserRequirementResponse>;
  getUserStreak: (token: string) => Promise<GetUserStreakResponse>;
}

const getUser = async (token: string) => {
  return await request(`${bumpeeURL}/users`, {
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    method: 'GET',
  });
};

const updateUser = async (token: string, params: UserParams) => {
  return await request(`${bumpeeURL}/users`, {
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    method: 'PUT',
    body: JSON.stringify(params),
  });
};

const createUser = async (token: string, params: UserParams) => {
  return await request(`${bumpeeURL}/users`, {
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    method: 'POST',
    body: JSON.stringify(params),
  });
};

const getLanguages = async (token: string) => {
  return await request(`${bumpeeBeverlyURL}/languages`, {
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    method: 'GET',
  });
};

const getTopics = async (token: string) => {
  return await request(`${bumpeeBeverlyURL}/topics`, {
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    method: 'GET',
  });
};

const createChat = async (token: string, params: CreateChatParams) => {
  return await request(`${bumpeeBeverlyURL}/chats`, {
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    method: 'POST',
    body: JSON.stringify(params),
  });
};

const getChats = async (token: string) => {
  return await request(`${bumpeeBeverlyURL}/chats`, {
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    method: 'GET',
  });
};

const getChat = async (token: string, chatId: number) => {
  return await request(`${bumpeeBeverlyURL}/chats/${chatId}`, {
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    method: 'GET',
  });
};

const deleteChat = async (token: string, chatId: number) => {
  return await request(`${bumpeeBeverlyURL}/chats/${chatId}`, {
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    method: 'DELETE',
  });
};

const getMessages = async (token: string, chatId: number, pageParam = 1) => {
  const data: {
    page: number;
    per_page: number;
  } = {
    page: pageParam,
    per_page: 20,
  };

  const queryParams = Object.keys(data)
    .map((key) => `${key}=${data[key as keyof typeof data]}`)
    .join('&');

  return await request(
    `${bumpeeBeverlyURL}/chats/${chatId}/messages?${queryParams}`,
    {
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      method: 'GET',
    }
  );
};

const createMessage = async (token: string, params: CreateMessageParams) => {
  const { chat_id } = params;
  return await request(`${bumpeeBeverlyURL}/chats/${chat_id}/messages`, {
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    method: 'POST',
    body: JSON.stringify(params),
  });
};

const getMessageTranslation = async (
  token: string,
  chatId: string | number,
  messageId: string | number
) => {
  return await request(
    `${bumpeeBeverlyURL}/chats/${chatId}/messages/${messageId}/translate`,
    {
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      method: 'GET',
    }
  );
};

const getTextTranslation = async (
  token: string,
  text: string,
  chatId: string | number
) => {
  return await request(
    `${bumpeeBeverlyURL}/translations/text?chat_id=${chatId}&text=${text}`,
    {
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      method: 'GET',
    }
  );
};

const getHint = async (
  token: string,
  chatId?: string | number,
  messageId?: string | number
) => {
  return await request(
    `${bumpeeBeverlyURL}/chats/${chatId}/messages/${messageId}/hints`,
    {
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      method: 'GET',
    }
  );
};

const getLessonChat = async (token: string, lessonChatId: string) => {
  return await request(`${bumpeeBeverlyURL}/lessons_chats/${lessonChatId}`, {
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    method: 'GET',
  });
};

const createLessonChatMessage = async (
  token: string,
  params: any,
  lessonChatId: string | number
) => {
  const lessonChatParams = {
    answer: params.content,
  };
  return await request(
    `${bumpeeBeverlyURL}/lessons_chats/${lessonChatId}/answer`,
    {
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      method: 'PUT',
      body: JSON.stringify(lessonChatParams),
    }
  );
};

const getWordTranslation = async (
  token: string,
  chatId: number,
  messageId: number,
  index: number
) => {
  return await request(
    `${bumpeeBeverlyURL}/chats/${chatId}/messages/${messageId}/translate_word?word_index=${index}`,
    {
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      method: 'GET',
    }
  );
};

const getMessageAudio = async (
  token: string,
  chatId?: string | number,
  messageId?: string | number
) => {
  const response = await request(
    `${bumpeeBeverlyURL}/chats/${chatId}/messages/${messageId}/listen`,
    {
      headers: {
        'content-type': 'audio/mpeg',
        Accept: 'audio/mpeg',
        Authorization: `Bearer ${token}`,
      },
      method: 'POST',
    }
  );

  return response;
};

const getHintAudio = async (
  token: string,
  chatId: string | number,
  text: string
) => {
  const response = await request(
    `${bumpeeBeverlyURL}/chats/${chatId}/hint_listen`,
    {
      headers: {
        'content-type': 'application/json',
        Accept: 'audio/mpeg',
        Authorization: `Bearer ${token}`,
      },
      method: 'POST',
      body: JSON.stringify({
        text,
      }),
    }
  );

  return response;
};

const getLessonChats = async (token: string) => {
  return await request(`${bumpeeBeverlyURL}/lessons_chats`, {
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    method: 'GET',
  });
};

const completeLesson = async (
  token: string,
  params: any,
  lessonChatId: string
) => {
  const completeLessonParams = {
    answer: params.content,
  };
  return await request(
    `${bumpeeBeverlyURL}/lessons_chats/${lessonChatId}/complete`,
    {
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      method: 'PUT',
      body: JSON.stringify(completeLessonParams),
    }
  );
};

const createLessonChat = async (
  token: string,
  params: CreateLessonChatParams
) => {
  return await request(`${bumpeeBeverlyURL}/lessons_chats`, {
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    method: 'POST',
    body: JSON.stringify({ lesson_chat: params }),
  });
};

const getQuizChats = async (token: string) => {
  return await request(`${bumpeeBeverlyURL}/quiz_chats`, {
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    method: 'GET',
  });
};

const getQuizChat = async (token: string, quizChatId: string) => {
  return await request(`${bumpeeBeverlyURL}/quiz_chats/${quizChatId}`, {
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    method: 'GET',
  });
};

const createQuizChat = async (token: string, params: CreateQuizChatParams) => {
  return await request(`${bumpeeBeverlyURL}/quiz_chats`, {
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    method: 'POST',
    body: JSON.stringify({ quiz_chat: params }),
  });
};

const createQuizChatMessage = async (
  token: string,
  params: any,
  quizChatId: number
) => {
  return await request(`${bumpeeBeverlyURL}/quiz_chats/${quizChatId}/answer`, {
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    method: 'PUT',
    body: JSON.stringify({
      question_id: params.currentQuestionId,
      answer: params.content,
    }),
  });
};

const retakeQuiz = async (token: string, quizChatId: number) => {
  return await request(`${bumpeeBeverlyURL}/quiz_chats/${quizChatId}/retake`, {
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    method: 'PUT',
    body: JSON.stringify({
      id: quizChatId,
    }),
  });
};

const getStoryChats = async (token: string) => {
  return await request(`${bumpeeBeverlyURL}/story_chats`, {
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    method: 'GET',
  });
};

const getStoryChat = async (token: string, storyChatId: string) => {
  return await request(`${bumpeeBeverlyURL}/story_chats/${storyChatId}`, {
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    method: 'GET',
  });
};

const createStoryChat = async (
  token: string,
  params: CreateStoryChatParams
) => {
  return await request(`${bumpeeBeverlyURL}/story_chats`, {
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    method: 'POST',
    body: JSON.stringify({ story_chat: params }),
  });
};

const createStoryChatMessage = async (
  token: string,
  params: any,
  storyChatId: number
) => {
  return await request(
    `${bumpeeBeverlyURL}/story_chats/${storyChatId}/answer`,
    {
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      method: 'POST',
      body: JSON.stringify({
        answer: params.content,
      }),
    }
  );
};

const getStoryAudio = async (
  token: string,
  chatId: string | number,
  segmentIndex: string | number
) => {
  const response = await request(
    `${bumpeeBeverlyURL}/story_chats/${chatId}/listen`,
    {
      headers: {
        'content-type': 'application/json',
        Accept: 'audio/mpeg',
        Authorization: `Bearer ${token}`,
      },
      method: 'POST',
      body: JSON.stringify({ segment_index: segmentIndex }),
    }
  );

  return response;
};

const getUserAchievements = async (token: string) => {
  return await request(`${bumpeeURL}/achievements`, {
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    method: 'GET',
  });
};

const createUserRequirement = async (
  token: string,
  params: CreateUserRequirementParams
) => {
  return await request(`${bumpeeURL}/user_requirements`, {
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    method: 'POST',
    body: JSON.stringify(params),
  });
};

const getUserStreak = async (token: string) => {
  return await request(`${bumpeeURL}/streaks`, {
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    method: 'GET',
  });
};

export const BumpeeRequests: BumpeeRequestsInterface = {
  createUser,
  getUser,
  updateUser,
  getLanguages,
  getTopics,
  createChat,
  getChats,
  getChat,
  deleteChat,
  getMessages,
  createMessage,
  getLessonChat,
  createLessonChatMessage,
  getMessageTranslation,
  getTextTranslation,
  getHint,
  getWordTranslation,
  getLessonChats,
  createLessonChat,
  completeLesson,
  getMessageAudio,
  getHintAudio,
  getQuizChats,
  getQuizChat,
  createQuizChat,
  createQuizChatMessage,
  retakeQuiz,
  getStoryChats,
  getStoryChat,
  createStoryChat,
  getStoryAudio,
  createStoryChatMessage,
  getUserAchievements,
  createUserRequirement,
  getUserStreak,
};
