import { SvgIcon, type SvgIconProps } from '@ltvco/refresh-lib/theme';

export const MemberPerks = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>Member Perks</title>
        <path
          d="M20.5652 12.7527L17.956 11.9231C17.8685 11.8944 17.7757 11.8801 17.6823 11.881H0.962756C0.856665 11.8801 0.751427 11.8977 0.653205 11.9327C0.554983 11.9677 0.465748 12.0194 0.390727 12.0849C0.315706 12.1504 0.256406 12.2283 0.216295 12.3141C0.176185 12.3998 0.156072 12.4917 0.157133 12.5843V22.4733C0.15665 22.7504 0.218819 23.0249 0.340076 23.2809C0.461333 23.537 0.639294 23.7697 0.863761 23.9656C1.08823 24.1616 1.35479 24.3169 1.64816 24.4228C1.94153 24.5286 2.25595 24.5829 2.57339 24.5825H18.6803C18.9978 24.5829 19.3122 24.5286 19.6056 24.4228C19.8989 24.3169 20.1655 24.1616 20.39 23.9656C20.6144 23.7697 20.7924 23.537 20.9137 23.2809C21.0349 23.0249 21.0971 22.7504 21.0966 22.4733V13.4133C21.0974 13.2687 21.0467 13.1275 20.9515 13.0091C20.8563 12.8908 20.7213 12.8012 20.5652 12.7527Z"
          fill="#FC1A40"
        />
        <path
          d="M21.0966 13.4134V22.4728C21.097 22.7499 21.0349 23.0244 20.9136 23.2805C20.7924 23.5366 20.6144 23.7692 20.3899 23.9652C20.1655 24.1611 19.8989 24.3165 19.6055 24.4223C19.3122 24.5282 18.9977 24.5824 18.6803 24.582H10.6265V11.8816H17.6817C17.7751 11.8807 17.8679 11.895 17.9553 11.9237L20.5646 12.7533C20.7207 12.8016 20.8557 12.8911 20.9511 13.0094C21.0464 13.1276 21.0972 13.2688 21.0966 13.4134Z"
          fill="#C60034"
        />
        <path
          d="M20.8064 5.17122C20.6716 4.69396 20.3975 4.25533 20.0108 3.89803C19.624 3.54074 19.1379 3.27697 18.5997 3.13242C18.0657 2.984 17.4964 2.96024 16.9486 3.06351C16.4008 3.16677 15.8936 3.39347 15.4773 3.72102L13.609 5.15522L13.2388 3.00392C13.1547 2.51806 12.9281 2.05946 12.5809 1.67268C12.2337 1.2859 11.7778 0.984113 11.2574 0.796645C11.0542 0.724618 10.8427 0.671887 10.6265 0.639363C10.1865 0.56105 9.73246 0.564391 9.29399 0.649171C8.85552 0.73395 8.44237 0.898272 8.08137 1.13147C7.60009 1.43382 7.22267 1.84529 6.98996 2.32137C6.75724 2.79745 6.67807 3.32001 6.76103 3.83252C6.84399 4.34504 7.08591 4.828 7.46062 5.22915C7.83534 5.63031 8.32858 5.93439 8.88699 6.10851L10.6265 6.6566L12.7685 7.34544H12.7844L16.5053 8.54078C16.8678 8.65141 17.2488 8.70853 17.6328 8.70979C18.1288 8.70808 18.618 8.60825 19.0635 8.4178C19.509 8.22735 19.8992 7.95123 20.2049 7.61021C20.5106 7.26918 20.7237 6.87212 20.8283 6.44885C20.9329 6.02558 20.9263 5.58712 20.8089 5.16642L20.8064 5.17122Z"
          fill="#FE9923"
        />
        <path
          d="M19.8078 7.98144C19.211 8.45079 18.4367 8.71111 17.6334 8.7124C17.2494 8.71112 16.8684 8.654 16.5059 8.54339L12.785 7.34804H12.7691L10.6271 6.6592V0.641968C10.8421 0.673836 11.0526 0.725669 11.255 0.796584C11.7753 0.984052 12.2313 1.28584 12.5785 1.67262C12.9256 2.0594 13.1523 2.518 13.2364 3.00386L13.6065 5.15516L15.4749 3.72096C15.8912 3.39267 16.3987 3.16537 16.947 3.06171C17.4953 2.95806 18.0651 2.98168 18.5997 3.13022C19.1528 3.27902 19.6508 3.55355 20.0423 3.92568C20.4339 4.29781 20.7051 4.75415 20.828 5.24791C20.951 5.74166 20.9213 6.25508 20.742 6.73552C20.5627 7.21597 20.2403 7.64615 19.8078 7.98197V7.98144Z"
          fill="#FE8821"
        />
        <path
          d="M23.8512 11.5579L22.7561 14.2013C22.696 14.3339 22.5917 14.4477 22.4566 14.5279C22.3216 14.6082 22.1621 14.6511 21.9987 14.6513C21.9053 14.6522 21.8125 14.6379 21.7251 14.6092L13.397 11.9658L12.6403 9.78621L10.6247 10.1797L8.85343 10.531L0.527241 7.88816C0.328828 7.82202 0.167573 7.69123 0.0774511 7.52335C-0.0126711 7.35547 -0.024624 7.16361 0.0441118 6.98819L1.15574 4.34479C1.26199 4.08345 1.42661 3.84312 1.64005 3.63771C1.85348 3.43231 2.11149 3.26593 2.39912 3.1482C2.68676 3.03047 2.9983 2.96374 3.31569 2.95187C3.63308 2.94 3.95002 2.98324 4.24813 3.07907L10.6265 5.10507L11.0614 5.24582C11.0614 5.24582 12.8009 8.00173 12.8492 8.00173C12.8815 8.00173 13.5742 7.66424 14.2503 7.34061C14.9264 7.01698 15.6032 6.68002 15.6032 6.68002L22.4006 8.84518C23.0041 9.03901 23.4956 9.43268 23.7688 9.94082C23.9056 10.1919 23.9835 10.4643 23.9976 10.7421C24.0118 11.0198 23.962 11.2972 23.8512 11.5579Z"
          fill="#FF3E75"
        />
        <path
          d="M23.8506 11.5558L22.7555 14.1992C22.6953 14.3318 22.591 14.4455 22.456 14.5258C22.3209 14.606 22.1614 14.649 21.9981 14.6492C21.9047 14.6501 21.8119 14.6358 21.7245 14.6071L13.3971 11.9637L12.6403 9.78415L10.6247 10.1776V5.10461L11.0596 5.24537C11.0596 5.24537 12.7991 8.00127 12.8474 8.00127C12.8797 8.00127 13.5724 7.66378 14.2485 7.34015C14.9246 7.01652 15.6014 6.67956 15.6014 6.67956L22.3988 8.84473C23.0029 9.03856 23.495 9.43268 23.7682 9.94143C23.9046 10.1921 23.9822 10.4641 23.9964 10.7414C24.0106 11.0186 23.961 11.2956 23.8506 11.5558Z"
          fill="#FC1A40"
        />
        <path
          d="M8.21092 11.8821V24.583H13.0428V11.8821H8.21092Z"
          fill="#FCBF29"
        />
        <path
          d="M11.0621 5.24585L10.6272 6.28604L8.85593 10.5321L10.6272 11.0946L12.6428 11.7275L13.3995 11.9663L15.6063 6.67951L11.0621 5.24585Z"
          fill="#FCBF29"
        />
        <path
          d="M13.0429 11.8815H10.6266V24.5824H13.0429V11.8815Z"
          fill="#FE9923"
        />
        <path
          d="M15.6038 6.67896L13.3971 11.9636L12.6403 11.7248L10.6247 11.0919V6.28336L11.0596 5.24316L15.6038 6.67896Z"
          fill="#FE9923"
        />
      </svg>
    </SvgIcon>
  );
};
