import { JsonGedcomData } from '@ltvco/refresh-lib/fmt';
import { CustomIcons, useTheme, Stack, Badge } from '@ltvco/refresh-lib/theme';
import type { Connection, NavLinkData, Owner } from '@ltvco/refresh-lib/v1';
import {
  Star,
  Gavel,
  AccountCircle,
  Phone,
  FamilyRestroom,
  People,
  Work,
  TaskAlt,
} from '@mui/icons-material';
import { useFeatureIsOn } from '@ltvco/refresh-lib/vendors';

const relativesCount = (relatives: Connection[]) => {
  let numRelatives = 0;

  relatives?.forEach((relative: Connection) => {
    numRelatives += 1;
    if (relative?.relatives?.length) {
      numRelatives += relative?.relatives?.length;
    }
  });

  return numRelatives;
};

export const getPersonNavLinkData = (
  reportOwner: Owner,
  showCriminalData: boolean,
  showBankruptcyData: boolean,
  hideCriminalBJLData: boolean,
  reportUpgraded: boolean,
  bonusDataLength: number,
  filteredRelatives: Connection[],
  familyTreeRelatives: JsonGedcomData['indis']
) => {
  let criminalBJLNavLinks: NavLinkData[] = [];

  if (!hideCriminalBJLData) {
    criminalBJLNavLinks = [
      {
        href: '#criminal-or-traffic-section',
        sectionId: '#criminal-or-traffic-section',
        text: 'Criminal or Traffic',
        reportItemsCount: reportOwner?.courts.criminal?.length || 0,
        showIcon: !showCriminalData,
        icon: <Star fontSize="small" />,
      },
      {
        href: '#bankruptcies-section',
        sectionId: '#bankruptcies-section',
        text: 'Bankruptcies',
        reportItemsCount: reportOwner?.courts.bankruptcy?.length || 0,
        showIcon: !showBankruptcyData,
        icon: <Star fontSize="small" />,
      },
    ];
  }

  let ObituaryDataLink: NavLinkData[] = [];

  if (reportOwner?.obituary?.funeralHome) {
    ObituaryDataLink = [
      {
        href: '#obituary-section',
        sectionId: '#obituary-section',
        text: 'Obituary Details',
        hideReportItemsCount: true,
        reportItemsCount: null,
      },
    ];
  }

  let GraveDataLink: NavLinkData[] = [];

  if (reportOwner?.grave) {
    GraveDataLink = [
      {
        href: '#burial-information-section',
        sectionId: '#burial-information-section',
        text: 'Burial Information',
        hideReportItemsCount: true,
        reportItemsCount: null,
      },
    ];
  }

  let relativesAddedToFamilyTree = 0;
  familyTreeRelatives.forEach((relative) => {
    if (!relative.placeholder) {
      relativesAddedToFamilyTree += 1;
    }
  });

  // TODO: add notificationSection to data for all report types.
  return [
    {
      href: '#person-overview',
      sectionId: '#person-overview',
      text: 'Overview',
      reportItemsCount: null,
      hideReportItemsCount: true,
      notificationSection: 'identity',
    },
    ...ObituaryDataLink,
    ...GraveDataLink,
    {
      href: '#phone-numbers-section',
      sectionId: '#phone-numbers-section',
      text: 'Phone Numbers',
      reportItemsCount: reportOwner.phones.length,
      notificationSection: 'phones',
    },
    {
      href: '#email-section',
      sectionId: '#email-section',
      text: 'Email Addresses',
      reportItemsCount: reportOwner.emails.length,
      notificationSection: 'emails',
    },
    {
      href: '#address-history-section',
      sectionId: '#address-history-section',
      text: 'Address History',
      reportItemsCount: reportOwner.addresses.length,
      notificationSection: 'addresses',
    },
    {
      href: '#names-ancestry-section',
      sectionId: '#names-ancestry-section',
      text: 'Names & Ancestry',
      reportItemsCount: reportOwner?.identity?.names.length ? 1 : 0,
    },
    {
      href: '#family-tree-section',
      sectionId: '#family-tree-section',
      text: 'Family Tree',
      hideReportItemsCount: relativesAddedToFamilyTree == 0,
      reportItemsCount: relativesAddedToFamilyTree,
      notificationSection: 'family_tree',
    },
    {
      href: '#relatives-section',
      sectionId: '#relatives-section',
      text: 'Possible Relatives',
      reportItemsCount: relativesCount(filteredRelatives) || 0,
      notificationSection: 'relatives',
    },
    {
      href: '#associates-section',
      sectionId: '#associates-section',
      text: 'Associates',
      reportItemsCount: reportOwner?.associates?.length || 0,
      notificationSection: 'associates',
    },
    {
      href: '#neighbors-section',
      sectionId: '#neighbors-section',
      text: 'Neighbors',
      reportItemsCount: reportOwner?.neighbors?.length || 0,
      notificationSection: 'neighbors',
    },
    ...criminalBJLNavLinks,
    {
      href: '#jobs-section',
      sectionId: '#jobs-section',
      text: 'Jobs & Education',
      reportItemsCount: reportOwner.jobs.length + reportOwner.educations.length,
      notificationSection: 'jobs',
    },
    {
      href: '#social-media-section',
      sectionId: '#social-media-section',
      text: 'Social Media',
      reportItemsCount:
        reportOwner.profiles.length + reportOwner.usernames.length,
      notificationSection: 'socials',
    },
    {
      href: '#possible-owned-assets-section',
      sectionId: '#possible-owned-assets-section',
      text: 'Assets',
      reportItemsCount:
        reportOwner?.ownedAssets?.vehicles?.aircraft?.length +
          reportOwner?.ownedAssets?.vehicles?.watercraft?.length +
          reportOwner?.ownedAssets?.vehicles?.automobiles?.length +
          reportOwner?.ownedAssets?.real_estate?.length || 0,
      notificationSection: 'real_estate',
    },
    {
      href: '#additional-owner-details-section',
      sectionId: '#additional-owner-details-section',
      text: 'Free Bonus Data',
      reportItemsCount: bonusDataLength || 0,
      showIcon: !reportUpgraded,
      icon: <Star fontSize="small" />,
    },
  ];
};

function getCourtRecordsItem(reportOwner: Owner, reportUpgraded: boolean) {
  const theme = useTheme();
  const licensesCount =
    reportOwner.bonusData.licenses.dea.length +
    reportOwner.bonusData.licenses.faa.length +
    reportOwner.bonusData.licenses.professional.length +
    reportOwner.bonusData.licenses.sporting.length +
    reportOwner.bonusData.filings.business.length +
    reportOwner.bonusData.filings.ucc.length +
    reportOwner.bonusData.licenses.weapon.length;

  return [
    {
      ...(reportUpgraded ? {} : { href: '#court-records-section' }),
      labelText: 'Court Records',
      reportItemsCount: reportOwner?.courts.criminal?.length || 0,
      name: 'court-records',
      icons: [
        <Gavel
          sx={{ color: theme.palette.primary.main, fontSize: '1.4rem' }}
        />,
        <Stack spacing={1} direction="row" alignItems="center">
          <Badge
            sx={{
              backgroundColor: 'premium.main',
              color: 'premium.contrastText',
              borderRadius: 100,
              fontSize: '.875rem',
              paddingInline: 0.8125,
              lineHeight: 1.6,
            }}
          >
            Free
          </Badge>
          <Star
            sx={{
              marginLeft: 1,
              color: theme.palette.primary.main,
              fontSize: '1.4rem',
            }}
          />
        </Stack>,
      ],
      showIcon: !reportUpgraded,
      childItems: reportUpgraded
        ? [
            {
              href: '#criminal-or-traffic-section',
              labelText: 'Criminal or Traffic',
              reportItemsCount: reportOwner.courts.criminal.length,
            },
            {
              href: '#bankruptcies-section',
              labelText: 'Bankruptcies',
              reportItemsCount: reportOwner.courts.bankruptcy.length,
            },
            {
              href: '#licenses-and-permits-section',
              labelText: 'Licenses and Permits',
              reportItemsCount: licensesCount,
            },
          ]
        : [],
    },
  ];
}

export const getPersonNavLinkDataV2 = (
  reportOwner: Owner,
  hideCriminalBJLData: boolean,
  reportUpgraded: boolean,
  filteredRelatives: Connection[],
  familyTreeRelatives: JsonGedcomData['indis']
) => {
  const theme = useTheme();
  const courtRecordsItem = !hideCriminalBJLData
    ? getCourtRecordsItem(reportOwner, reportUpgraded)
    : [];

  const isInUpdatedUsernameAB = useFeatureIsOn('RFRSH-2700');
  const contactItems = !isInUpdatedUsernameAB
    ? [
        {
          href: '#social-media-section',
          labelText: 'Social Media',
          reportItemsCount:
            reportOwner.profiles.length + reportOwner.usernames.length,
          name: 'social-media',
        },
      ]
    : [
        {
          href: '#social-media-section',
          labelText: 'Social Media',
          reportItemsCount: reportOwner.profiles.length,
          name: 'social-media',
        },
        {
          href: '#username-section',
          labelText: 'Usernames',
          reportItemsCount: reportOwner.usernames.length,
          name: 'username',
        },
      ];

  const maritalRecordsItem =
    reportOwner?.marital?.marriages?.length ||
    reportOwner?.marital?.divorces?.length
      ? [
          {
            href: '#marital-records-section',
            labelText: 'Marital Records',
            reportItemsCount:
              reportOwner?.marital?.marriages?.length +
              reportOwner?.marital?.divorces?.length,
          },
        ]
      : [];

  const obituaryDetailsItem = reportOwner?.obituary?.funeralHome
    ? [
        {
          href: '#obituary-section',
          labelText: 'Obituary Details',
          reportItemsCount: null, // Assuming you want to hide the count or it's not applicable
        },
      ]
    : [];

  const burialInformationItem = reportOwner?.grave
    ? [
        {
          href: '#burial-information-section',
          labelText: 'Burial Information',
          reportItemsCount: null, // Assuming you want to hide the count or it's not applicable
        },
      ]
    : [];

  let relativesAddedToFamilyTree = 0;
  familyTreeRelatives.forEach((relative) => {
    if (!relative.placeholder) {
      relativesAddedToFamilyTree += 1;
    }
  });
  return [
    {
      href: '#person-overview',
      labelText: 'Overview',
      icons: [
        <AccountCircle
          sx={{ color: theme.palette.primary.main, fontSize: '2.5rem' }}
        />,
      ],
      notificationSection: 'identity',
      name: 'person-overview',
    },
    {
      labelText: 'Contact Information',
      icons: [
        <Phone
          sx={{ color: theme.palette.primary.main, fontSize: '1.4rem' }}
        />,
      ],
      name: 'contact-information',
      childItems: [
        {
          href: '#phone-numbers-section',
          labelText: 'Phone Numbers',
          reportItemsCount: reportOwner.phones.length,
          name: 'phone-numbers',
          notificationSection: 'phones',
        },
        {
          href: '#email-section',
          labelText: 'Email Addresses',
          reportItemsCount: reportOwner.emails.length,
          name: 'email-addresses',
          notificationSection: 'emails',
        },
        {
          href: '#address-history-section',
          labelText: 'Address History',
          reportItemsCount: reportOwner.addresses.length,
          name: 'address-history',
          notificationSection: 'addresses',
        },
        ...contactItems,
      ],
    },
    {
      labelText: 'Family & Ancestry',
      name: 'family-ancestry',
      icons: [
        <FamilyRestroom
          sx={{ color: theme.palette.primary.main, fontSize: '1.4rem' }}
        />,
      ],
      childItems: [
        {
          href: '#names-ancestry-section',
          labelText: 'Name Origin',
          reportItemsCount: reportOwner?.identity?.names.length ? 1 : 0,
          name: 'names-ancestry',
        },
        {
          href: '#family-tree-section',
          labelText: 'Family Tree',
          reportItemsCount: relativesAddedToFamilyTree,
          name: 'family-tree',
          notificationSection: 'family_tree',
        },
        {
          href: '#relatives-section',
          labelText: 'Possible Relatives',
          reportItemsCount: relativesCount(filteredRelatives) || 0,
          name: 'possible-relatives',
          notificationSection: 'relatives',
        },
        ...maritalRecordsItem,
        ...obituaryDetailsItem,
        ...burialInformationItem,
      ],
    },
    {
      labelText: 'Associates',
      name: 'associates',
      icons: [
        <People
          sx={{ color: theme.palette.primary.main, fontSize: '1.4rem' }}
        />,
      ],
      childItems: [
        {
          href: '#associates-section',
          labelText: 'Associates',
          reportItemsCount: reportOwner?.associates?.length || 0,
          name: 'associates',
          notificationSection: 'associates',
        },
        {
          href: '#neighbors-section',
          labelText: 'Neighbors',
          reportItemsCount: reportOwner?.neighbors?.length || 0,
          name: 'neighbors',
          notificationSection: 'neighbors',
        },
      ],
    },
    ...courtRecordsItem,
    {
      labelText: 'Jobs & Education',
      name: 'jobs-education',
      icons: [
        <Work sx={{ color: theme.palette.primary.main, fontSize: '1.4rem' }} />,
      ],
      childItems: [
        {
          href: '#jobs-section',
          labelText: 'Jobs',
          reportItemsCount: reportOwner.jobs.length,
          name: 'jobs',
          notificationSection: 'jobs',
        },
        {
          href: '#education-section',
          labelText: 'Education',
          reportItemsCount: reportOwner.educations.length,
          name: 'education',
          notificationSection: 'educations',
        },
      ],
    },
    {
      labelText: 'Assets',
      icons: [<CustomIcons.OwnedAssetsIcon color="primary" fontSize="small" />],
      href: '#possible-owned-assets-section',
      name: 'assets',
      reportItemsCount:
        reportOwner?.ownedAssets?.vehicles?.aircraft?.length +
          reportOwner?.ownedAssets?.vehicles?.watercraft?.length +
          reportOwner?.ownedAssets?.vehicles?.automobiles?.length +
          reportOwner?.ownedAssets?.real_estate?.length || 0,
      notificationSection: ['real_estate', 'automobiles', 'vehicles'],
    },
    {
      labelText: 'Next Steps',
      name: 'next-steps',
      icons: [
        <TaskAlt
          sx={{ color: theme.palette.primary.main, fontSize: '1.4rem' }}
        />,
      ],
      childItems: [
        {
          href: '#Notes-section',
          labelText: 'Notes',
          hideReportItemsCount: true,
          name: 'notes',
        },
        {
          href: '#related-reports-section',
          labelText: 'Related Reports',
          hideReportItemsCount: true,
          name: 'related-reports',
        },
        {
          href: '#report-rating',
          labelText: 'Report Rating',
          hideReportItemsCount: true,
          name: 'report-rating',
        },
      ],
    },
  ];
};
