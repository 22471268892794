import { SvgIcon, type SvgIconProps } from '@ltvco/refresh-lib/theme';

export const LanguageTutor = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>Language Tutor</title>
        <g clip-path="url(#clip0_55_7643)">
          <path
            d="M21 16.5C21.825 16.5 22.5 15.825 22.5 15V1.5C22.5 0.675 21.825 0 21 0H1.5C0.675 0 0 0.675 0 1.5V22.5L6 16.5H21ZM3 15.255V3H19.5V13.5H4.755L3 15.255ZM30 7.5C30 6.675 29.325 6 28.5 6H25.5V19.5H6V22.5C6 23.325 6.675 24 7.5 24H24L30 30V7.5Z"
            fill="#806ED6"
          />
        </g>
        <defs>
          <clipPath id="clip0_55_7643">
            <rect width="30" height="30" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};
