import { SvgIcon, type SvgIconProps } from '@ltvco/refresh-lib/theme';

export const DarkWebScan = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>Dark Web Scan</title>
        <g clip-path="url(#clip0_260_313)">
          <path
            d="M3.62854 22.0048C3.30266 21.9088 3.0173 21.7369 2.80686 21.5099L0.757814 19.3035C0.461628 18.993 0.266918 18.6249 0.192375 18.2343C0.117833 17.8437 0.165961 17.4438 0.33214 17.073L2.1877 12.9173C2.39516 12.4473 2.78501 12.0473 3.29638 11.7797L6.01814 10.1632L17.9009 10.2901L20.7755 11.7797C21.2868 12.0473 21.6767 12.4473 21.8841 12.9173L23.7397 17.0709C23.9059 17.4417 23.954 17.8415 23.8795 18.2321C23.8049 18.6227 23.6103 18.9909 23.314 19.3013L21.2657 21.5093C21.0552 21.7364 20.7698 21.9083 20.444 22.0043L3.62854 22.0048Z"
            fill="#4482C3"
          />
          <path
            d="M9.56154 13.096L4.45022 8.86828C4.27248 8.71851 4.14759 8.53149 4.08905 8.32731C4.03051 8.12317 4.04054 7.90966 4.11806 7.70986C4.9004 5.71735 7.54281 0 12.0363 0C16.5297 0 19.1721 5.71735 19.9544 7.70986C20.032 7.90962 20.0422 8.12308 19.9837 8.32726C19.9253 8.5314 19.8006 8.71846 19.623 8.86828L14.5103 13.096H9.56154Z"
            fill="#285680"
          />
          <path
            d="M18.8625 21.3334H4.67337C3.96096 21.3334 3.38345 21.8109 3.38345 22.4V22.9333C3.38345 23.5225 3.96096 24 4.67337 24H18.8625C19.5749 24 20.1525 23.5225 20.1525 22.9333V22.4C20.1525 21.8109 19.5749 21.3334 18.8625 21.3334Z"
            fill="#95A5A5"
          />
          <path
            d="M19.0405 21.281H5.03197L4.61339 14.4565C4.59829 14.2847 4.62565 14.1121 4.69382 13.9493C4.76199 13.7865 4.86954 13.6369 5.00991 13.5096C5.15027 13.3823 5.32051 13.28 5.51026 13.2089C5.7 13.1378 5.90528 13.0993 6.11358 13.0959H17.9596C18.1679 13.0993 18.3733 13.1376 18.5631 13.2087C18.7529 13.2798 18.9232 13.3821 19.0636 13.5094C19.2041 13.6367 19.3117 13.7864 19.3799 13.9492C19.4481 14.112 19.4755 14.2846 19.4604 14.4565L19.0405 21.281Z"
            fill="#BDC3C7"
          />
          <path
            d="M15.5009 7.77436V8.56424C15.5009 9.10595 15.2411 9.6255 14.7786 10.009L13.7247 10.8805C13.5031 11.0638 13.24 11.2092 12.9505 11.3084C12.6609 11.4075 12.3506 11.4586 12.0372 11.4586C11.7238 11.4586 11.4135 11.4075 11.1239 11.3084C10.8343 11.2092 10.5712 11.0638 10.3496 10.8805L9.29447 10.0101C8.83194 9.62656 8.57219 9.10701 8.57213 8.5653V7.77436H10.5515C10.9449 7.77436 11.3223 7.64513 11.6004 7.4151C11.8787 7.18507 12.0349 6.87307 12.0349 6.54773C12.0349 6.87307 12.1912 7.18507 12.4694 7.4151C12.7476 7.64513 13.1249 7.77436 13.5184 7.77436H15.5009Z"
            fill="#FDD7AD"
          />
          <path
            d="M15.5004 6.35206V7.77609H13.5197C13.3249 7.77609 13.132 7.74433 12.9521 7.68272C12.7721 7.62106 12.6085 7.53069 12.4708 7.41678C12.333 7.30287 12.2238 7.16764 12.1492 7.01884C12.0747 6.87 12.0363 6.71049 12.0363 6.54941V6.13873C13.1961 6.13112 14.3547 6.20247 15.5004 6.35206Z"
            fill="#35495E"
          />
          <path
            d="M12.0362 6.13873V6.5478C12.0362 6.87313 11.8799 7.18513 11.6017 7.41521C11.3235 7.64524 10.9462 7.77447 10.5528 7.77447H8.57213V6.35206C9.71777 6.20247 10.8764 6.13112 12.0362 6.13873Z"
            fill="#35495E"
          />
          <path
            d="M11.7679 18.6667C12.8365 18.6667 13.7028 17.9504 13.7028 17.0667C13.7028 16.183 12.8365 15.4667 11.7679 15.4667C10.6993 15.4667 9.83308 16.183 9.83308 17.0667C9.83308 17.9504 10.6993 18.6667 11.7679 18.6667Z"
            fill="#ECF0F1"
          />
          <path
            d="M19.9648 7.73866C18.6349 6.98386 17.1029 6.50797 15.501 6.35202C14.3554 6.20238 13.1968 6.13103 12.0369 6.13865C10.8771 6.13103 9.71849 6.20238 8.57286 6.35202C6.97094 6.50797 5.43894 6.98386 4.10906 7.73866C4.11422 7.73068 4.11422 7.7184 4.11873 7.70986C4.90043 5.71735 7.54281 0 12.0369 0C16.531 0 19.1728 5.71735 19.9551 7.70986C19.9602 7.7184 19.9602 7.73068 19.9648 7.73866Z"
            fill="#4482C3"
          />
        </g>
        <defs>
          <clipPath id="clip0_260_313">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};
