import { SvgIcon, type SvgIconProps } from '@ltvco/refresh-lib/theme';

export const FraudScan = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="36"
        height="32"
        viewBox="0 0 36 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>Fraud Scan</title>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.0455 30.4C10.4845 30.4 10.8409 30.7584 10.8409 31.2C10.8409 31.6416 10.4845 32 10.0455 32H4.47728C3.16478 32 2.09091 30.92 2.09091 29.6V24C2.09091 23.5584 2.44728 23.2 2.88637 23.2C3.32546 23.2 3.68182 23.5584 3.68182 24V29.6C3.68182 30.04 4.03978 30.4 4.47728 30.4H10.0455Z"
          fill="#3B3B3B"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M32.3182 24C32.3182 23.5584 32.6746 23.2 33.1137 23.2C33.5528 23.2 33.9091 23.5584 33.9091 24V29.6C33.9091 30.92 32.8353 32 31.5228 32H25.9546C25.5155 32 25.1591 31.6416 25.1591 31.2C25.1591 30.7584 25.5155 30.4 25.9546 30.4H31.5228C31.9603 30.4 32.3182 30.04 32.3182 29.6V24Z"
          fill="#3B3B3B"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M25.9546 1.6C25.5155 1.6 25.1591 1.2416 25.1591 0.800001C25.1591 0.3584 25.5155 0 25.9546 0H31.5228C32.8353 0 33.9091 1.08 33.9091 2.4V8.00001C33.9091 8.44161 33.5528 8.80001 33.1137 8.80001C32.6746 8.80001 32.3182 8.44161 32.3182 8.00001V2.4C32.3182 1.96 31.9603 1.6 31.5228 1.6H25.9546Z"
          fill="#3B3B3B"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.68182 8.00001C3.68182 8.44161 3.32546 8.80001 2.88637 8.80001C2.44728 8.80001 2.09091 8.44161 2.09091 8.00001V2.4C2.09091 1.08 3.16478 0 4.47728 0H10.0455C10.4845 0 10.8409 0.3584 10.8409 0.800001C10.8409 1.2416 10.4845 1.6 10.0455 1.6H4.47728C4.03978 1.6 3.68182 1.96 3.68182 2.4V8.00001Z"
          fill="#3B3B3B"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1.29547 16.8C0.856379 16.8 0.500015 16.4416 0.500015 16C0.500015 15.5584 0.856379 15.2 1.29547 15.2H34.7046C35.1437 15.2 35.5 15.5584 35.5 16C35.5 16.4416 35.1437 16.8 34.7046 16.8H1.29547Z"
          fill="#BAB5B5"
        />
        <path
          d="M27.7306 7.11072C26.218 6.9497 24.7293 6.61159 23.2949 6.10327C21.9789 5.63595 20.7165 5.02856 19.529 4.29145C19.2193 4.10086 18.8632 4 18.5 4C18.1368 4 17.7807 4.10086 17.471 4.29145C16.2835 5.02857 15.0211 5.63597 13.7051 6.10327C12.2707 6.6116 10.782 6.94972 9.26943 7.11075C8.78395 7.16083 8.33423 7.39015 8.00735 7.75431C7.68047 8.11846 7.49968 8.59155 7.5 9.08196V14.9719C7.50078 17.1452 8.0795 19.2788 9.17627 21.1519C10.273 23.025 11.848 24.5696 13.7382 25.6258L17.5404 27.7481C17.8333 27.9132 18.1635 27.9999 18.4994 28C18.8352 28.0001 19.1655 27.9136 19.4585 27.7487L23.2618 25.6258C25.152 24.5696 26.727 23.025 27.8237 21.1519C28.9205 19.2788 29.4992 17.1452 29.5 14.9719V9.08196C29.5003 8.59154 29.3195 8.11845 28.9927 7.75429C28.6658 7.39013 28.2161 7.1608 27.7306 7.11072Z"
          fill="#5095D4"
        />
        <path
          d="M17.3613 20C17.0086 20.0004 16.6681 19.8701 16.4052 19.6339L12.9862 16.581C12.8443 16.455 12.7285 16.3021 12.6456 16.1311C12.5626 15.9601 12.514 15.7744 12.5026 15.5845C12.4912 15.3946 12.5172 15.2043 12.5791 15.0246C12.641 14.8448 12.7377 14.679 12.8634 14.5369C12.9892 14.3947 13.1417 14.2788 13.3121 14.1959C13.4825 14.113 13.6676 14.0647 13.8566 14.0538C14.0456 14.0428 14.2349 14.0695 14.4137 14.1321C14.5925 14.1948 14.7572 14.2924 14.8984 14.4191L17.2699 16.5362L22.0106 11.4562C22.2719 11.1769 22.6327 11.0131 23.014 11.0008C23.3953 10.9884 23.766 11.1285 24.0446 11.3902C24.3232 11.6527 24.4867 12.0155 24.4992 12.399C24.5117 12.7825 24.3723 13.1553 24.1114 13.4355L18.4123 19.5432C18.2778 19.688 18.1151 19.8032 17.9342 19.8818C17.7534 19.9604 17.5583 20.0006 17.3613 20Z"
          fill="white"
        />
      </svg>
    </SvgIcon>
  );
};
