import { SvgIcon, SvgIconProps } from '@ltvco/refresh-lib/theme';

export const BVLogo = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 40 40" {...props}>
      <circle cx="20" cy="20" r="20" fill="#EBEAF4" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 21.1414C7 13.8937 12.9791 8 20.3519 8C27.7247 8 33.7037 13.8937 33.6431 21.1414C33.6431 26.1192 30.8556 30.4399 26.7349 32.67L27.3409 24.6657C27.3409 23.4312 26.2905 22.4356 24.9978 22.4356H15.6454C14.3526 22.4356 13.3022 23.4312 13.3022 24.6657L13.9082 32.67C9.78753 30.42 7 26.0993 7 21.1414ZM14.6962 15.9244C14.6962 19.0106 17.2414 21.4995 20.3521 21.4995C23.4628 21.4995 26.008 19.0106 26.008 15.9244C26.008 12.8382 23.4628 10.3493 20.3521 10.3493C17.2212 10.3493 14.6962 12.8581 14.6962 15.9244Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.7855 29.0363L26.2424 27.4433H23.1192L22.1823 25.1943L21.2453 27.4433H18.1013L20.579 29.0363L19.3714 31.9225L22.1823 30.0859L24.9931 31.9225L23.7855 29.0363Z"
      />
    </SvgIcon>
  );
};
