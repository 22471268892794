import { SvgIcon, type SvgIconProps } from '@ltvco/refresh-lib/theme';

export const Identity = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>Identity</title>
        <mask
          id="mask0_260_44"
          style={{
            maskType: 'luminance',
          }}
          maskUnits="userSpaceOnUse"
          x={0}
          y={0}
          width={24}
          height={24}
        >
          <path d="M24.4918 0H0.719971V24H24.4918V0Z" fill="white" />
        </mask>
        <g mask="url(#mask0_260_44)">
          <path
            d="M21.768 3.42822H3.4438C2.72158 3.42893 2.02912 3.76028 1.51843 4.34953C1.00775 4.93878 0.720581 5.73779 0.719971 6.5711V20.8571C0.720581 21.6905 1.00775 22.4895 1.51843 23.0787C2.02912 23.668 2.72158 23.9993 3.4438 24H21.768C22.4902 23.9993 23.1827 23.668 23.6934 23.0787C24.2041 22.4895 24.4912 21.6905 24.4918 20.8571V6.5711C24.4912 5.73779 24.2041 4.93878 23.6934 4.34953C23.1827 3.76028 22.4902 3.42893 21.768 3.42822Z"
            fill="white"
          />
          <path
            d="M14.8344 0H10.3805C9.92097 0.000703311 9.48049 0.211627 9.15557 0.586518C8.83069 0.961412 8.64789 1.46967 8.64725 1.99985V4.28591C8.64789 4.81606 8.83069 5.32435 9.15557 5.69922C9.48049 6.07412 9.92097 6.28505 10.3805 6.28574H14.8344C15.2939 6.28505 15.7344 6.07412 16.0593 5.69922C16.3842 5.32435 16.567 4.81606 16.5676 4.28591V1.99985C16.567 1.46967 16.3842 0.961412 16.0593 0.586518C15.7344 0.211627 15.2939 0.000703311 14.8344 0Z"
            fill="#ABB1B4"
          />
          <path
            d="M20.7774 10.2863H15.3297C15.1327 10.2863 14.9437 10.196 14.8044 10.0352C14.665 9.87442 14.5868 9.65639 14.5868 9.42904C14.5868 9.20169 14.665 8.98361 14.8044 8.82285C14.9437 8.6621 15.1327 8.57178 15.3297 8.57178H20.7774C20.9744 8.57178 21.1634 8.6621 21.3027 8.82285C21.442 8.98361 21.5203 9.20169 21.5203 9.42904C21.5203 9.65639 21.442 9.87442 21.3027 10.0352C21.1634 10.196 20.9744 10.2863 20.7774 10.2863Z"
            fill="#607D8B"
          />
          <path
            d="M20.7774 14.8576H15.3297C15.1327 14.8576 14.9437 14.7673 14.8044 14.6065C14.665 14.4457 14.5868 14.2277 14.5868 14.0003C14.5868 13.773 14.665 13.5549 14.8044 13.3941C14.9437 13.2333 15.1327 13.1431 15.3297 13.1431H20.7774C20.9744 13.1431 21.1634 13.2333 21.3027 13.3941C21.442 13.5549 21.5203 13.773 21.5203 14.0003C21.5203 14.2277 21.442 14.4457 21.3027 14.6065C21.1634 14.7673 20.9744 14.8576 20.7774 14.8576Z"
            fill="#607D8B"
          />
          <path
            d="M20.7774 19.4269H15.3297C15.1327 19.4269 14.9437 19.3366 14.8044 19.1758C14.665 19.015 14.5868 18.797 14.5868 18.5697C14.5868 18.3423 14.665 18.1242 14.8044 17.9634C14.9437 17.8027 15.1327 17.7124 15.3297 17.7124H20.7774C20.9744 17.7124 21.1634 17.8027 21.3027 17.9634C21.442 18.1242 21.5203 18.3423 21.5203 18.5697C21.5203 18.797 21.442 19.015 21.3027 19.1758C21.1634 19.3366 20.9744 19.4269 20.7774 19.4269Z"
            fill="#607D8B"
          />
          <path
            d="M8.54124 13.291H8.54584L10.696 12.4466C10.7029 12.4033 10.7058 12.3594 10.7045 12.3155C10.7053 12.2723 10.6994 12.2294 10.6868 12.1887C10.6485 12.0601 10.5821 11.9451 10.4941 11.8547V10.7471C10.5258 10.4917 10.4939 10.2313 10.4023 9.99596C10.3107 9.76067 10.163 9.56027 9.97652 9.41802C9.81472 9.03319 9.55344 8.7157 9.22872 8.50939C8.904 8.30309 8.5318 8.21812 8.16344 8.26612C7.58832 8.30258 7.04256 8.57096 6.62152 9.02442C6.20044 9.47789 5.9308 10.0876 5.85964 10.7471C5.85964 11.152 5.8466 11.4702 5.83356 11.7129C5.83356 11.7634 5.82896 11.8095 5.82896 11.8494C5.73564 11.949 5.6688 12.0771 5.6362 12.2189C5.63088 12.2507 5.62804 12.283 5.62776 12.3155C5.65264 12.6708 5.72652 13.0193 5.8466 13.3477C5.86032 13.3911 5.8814 13.4309 5.90856 13.4648C5.93568 13.4987 5.9684 13.5261 6.0048 13.5453C6.01168 13.582 6.01452 13.6195 6.01324 13.6569C6.07364 14.159 6.29404 14.6171 6.63144 14.9418C6.60992 15.4982 6.47324 15.8827 6.28048 15.9793L4.56187 16.6377C4.32293 16.7295 4.10798 16.8895 3.93695 17.1028C3.76591 17.3161 3.64435 17.5758 3.58353 17.8578L3.35315 18.9299C3.34229 18.9859 3.34216 19.0439 3.35277 19.0999C3.36339 19.1559 3.38448 19.2086 3.41459 19.2542C3.44534 19.2996 3.48461 19.3363 3.52943 19.3616C3.57425 19.3869 3.62347 19.4002 3.67338 19.4005H8.58808C8.45208 19.2001 8.32892 18.9887 8.21948 18.7678C7.89884 18.12 7.72948 17.3872 7.728 16.6412V14.6113C7.72884 14.3207 7.8078 14.0377 7.95404 13.8012C8.10028 13.5647 8.30652 13.3864 8.54432 13.291H8.54124Z"
            fill="#42A5F5"
          />
          <path
            d="M7.91384 13.291H7.90924L5.75904 12.4466C5.75236 12.403 5.7498 12.3588 5.75136 12.3146C5.75052 12.2714 5.75652 12.2285 5.76904 12.1879C5.80736 12.0593 5.87376 11.9443 5.96176 11.8538V10.7471C5.93012 10.4918 5.96192 10.2313 6.05356 9.99601C6.1452 9.76072 6.29284 9.56027 6.47936 9.41803C6.64112 9.03333 6.90224 8.71598 7.22684 8.50972C7.5514 8.30341 7.92344 8.2183 8.29164 8.26616C8.86676 8.30258 9.41252 8.57096 9.8336 9.02443C10.2546 9.47789 10.5243 10.0876 10.5954 10.7471C10.5954 11.1521 10.6085 11.4702 10.6216 11.7129C10.6216 11.7634 10.6262 11.8095 10.6262 11.8494C10.7194 11.949 10.7863 12.0771 10.8189 12.2189C10.8242 12.2507 10.8271 12.283 10.8274 12.3155C10.8025 12.6708 10.7286 13.0193 10.6085 13.3477C10.5948 13.3911 10.5737 13.4309 10.5466 13.4648C10.5194 13.4987 10.4867 13.5261 10.4503 13.5453C10.4434 13.582 10.4406 13.6195 10.4418 13.657C10.3814 14.159 10.161 14.6171 9.82368 14.9418C9.84516 15.4982 9.98188 15.8827 10.1746 15.9793L11.8948 16.6359C12.1337 16.7278 12.3486 16.8877 12.5197 17.1011C12.6907 17.3143 12.8123 17.5741 12.8731 17.8561L13.1035 18.9282C13.1143 18.9841 13.1145 19.0422 13.1038 19.0982C13.0932 19.1542 13.0722 19.2068 13.042 19.2525C13.0113 19.2979 12.972 19.3345 12.9272 19.3599C12.8824 19.3852 12.8332 19.3985 12.7832 19.3987H7.86852C7.99984 19.2064 7.47688 18.961 7.59056 18.7333C7.88004 18.1413 7.03456 18.3921 7.03456 17.5813L7.27952 14.9444C7.27952 14.3472 8.3984 13.4913 7.91152 13.2946L7.91384 13.291Z"
            fill="#42A5F5"
          />
        </g>
      </svg>
    </SvgIcon>
  );
};
